@import "components/reset";

// VARIABLES
// --------------------------------------

// Color pallette
$white: #FFFFFF;
$black: #363636;
$gray: #E6E6E6;
$darkgray: lighten($black, 39%);
$offblack: #4A4A4A;
$transaction: #5DC3BC; // (teal)
$product: #FA7608; // (orange)
$alert: #D0011B; // (red)

// Body font
$body: 'Source Sans Pro', -apple-system, 'Helvetica Neue', Helvetica, Arial, sans-serif;
$regular: 400; // also supports italic
$bold: 700;

// Alt Heading font
$heading-alt: 'Alfa Slab One', Georgia, Times, 'Times New Roman', serif;

// Heading font
$heading: cheap-pine-sans, sans-serif;
$regular: 400;


// Duration + Timing
$dt_normal: 0.1875s ease-out;

// Border Radius
$br_normal: 0.5rem;

// Media queries
@mixin n480  { @media screen and (max-width: 29.9375em)	{ @content; } }
@mixin w480  { @media screen and (min-width: 30em)		{ @content; } }
@mixin n640  { @media screen and (max-width: 39.9375em)	{ @content; } }
@mixin w640  { @media screen and (min-width: 40em)		{ @content; } }
@mixin n1000 { @media screen and (max-width: 62.4375em)	{ @content; } }
@mixin w1000 { @media screen and (min-width: 62.5em)	{ @content; } }
@mixin n1440 { @media screen and (max-width: 89.9375em)	{ @content; } }
@mixin w1440 { @media screen and (min-width: 90em)		{ @content; } }
@mixin w1920 { @media screen and (min-width: 120em)		{ @content; } }





// BASE
// --------------------------------------
body {
	line-height: 1.65;
	font-family: $body;
	font-weight: $regular;
	color: $offblack;
	background-color: $white;
}

// Typography
// ----------
a {
	text-decoration: none;
	color: $transaction;
	transition: color $dt_normal;
}
strong {
	font-weight: $bold;
}
em {
	font-style: italic;
}
h1, h2, h3 {
	font-family: $heading;
	margin-bottom: 0.75rem;
	line-height: 1.25;
	color: $black;
	&:last-child {
		margin-bottom: 0;
	}
}
h1 {
	font-size: 1.6875em;
	font-size: 2.5em;
	line-height: 1;
	margin-bottom: 0.515625em;
}
h2 {
	font-size: 1.8em;
	letter-spacing: 0.5px;
}
h3 {
	font-size: 1.375em;
	margin-bottom: 0.375em;
	line-height: 1;
}
h4 {
	font-weight: $bold;
	margin-bottom: 0.40625em;
	color: $black;
}
p {
	margin-bottom: 1.5em;
	&:last-child {
		margin-bottom: 0;
	}
	a {
		color: $black;
		text-decoration: underline;
		&:hover {
			color: darken($transaction, 15%);
		}
	}
}
article {
	margin-top: 4em;
	h1 {
		margin-bottom: 0.875em;
	}
	ul, ol {
		margin-left: 1.5em;
		margin-bottom: 1.5em;
		li:nth-last-child(n+2) {
			margin-bottom: 0.75em;
		}
	}
	ul {
		list-style-type: disc;
	}
	ol {
		&[type="a"] {
			list-style-type: lower-alpha;
		}
		&[type="i"] {
			list-style-type: decimal;
		}
	}
}

// Inputs
// ------
select, input, textarea {
		-webkit-appearance: none;
	appearance: none;
	font-family: $heading;
	margin: 0;
	font-size: 1.375em;
	display: block;
	color: $black;
	border: 3px solid currentColor;
	border-radius: $br_normal;
	line-height: 1.65;
	line-height: 1;
	width: 100%;
	padding: 0.375em 0.4375em;
	text-align: center;
	&[type="number"] {
		// hides spinner in Webkit
		&::-webkit-inner-spin-button,
		&::-webkit-outer-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}
		// hides spinner in Firefox
		-moz-appearance: textfield;
	}
	// setting up some default placeholder styles… auto-prefixer couldn’t generate the cross-browser versions
	&::-webkit-input-placeholder	{ color: darken($gray, 12.5%); }
	&:-moz-placeholder				{ color: darken($gray, 12.5%); }
	&::-moz-placeholder				{ color: darken($gray, 12.5%); }
	&:-ms-input-placeholder			{ color: darken($gray, 12.5%); }
}
button { // button input style clearing
		-webkit-appearance: none;
	appearance: none;
	font-size: 1em;
	margin: 0;
	border: none;
	display: block;
	color: $black;
	border-radius: $br_normal;
	line-height: 1.65;
	width: 100%;
	cursor: pointer;
	&.link {
		all: unset;
		border: none;
		background-color: transparent;
		font-family: inherit;
		padding: 0;
		cursor: pointer;
		font-size: 1em;
		text-decoration: none;
		color: $transaction;
		transition: color 0.1875s ease-out;
		display: inline;
		width: auto;
		@media screen and (-ms-high-contrast: active) {
			border: 2px solid currentcolor;
		}
		&:hover {
			background-color: unset;
		}
	}
}
.styled-select {
	position: relative;
	color: $black;
	border: 3px solid currentColor;
	border-radius: $br_normal;
	transition:
		color $dt_normal,
		border-color $dt_normal,
		width $dt_normal
	;
	&:before {
		content: "";
		position: absolute;
		top: 1.125em;
		right: 1.125em;
		width: 0;
	    height: 0;
	    border-left: 0.4125em solid transparent;
	    border-right: 0.4125em solid transparent;

	    border-top: 0.5em solid currentColor;
		pointer-events: none;
		z-index: 20;
	}
	&:after {
		content: "";
		position: absolute;
		right: 0;
		top: 0;
		height: 100%;
		width: 4em;
		box-shadow: inset -6em 0 0.625em -3.5em white;
		pointer-events: none;
		z-index: 10;
		border-radius: $br_normal;
	}
	&.is_disabled {
		opacity: 0.51;
	}
	select {
		cursor: pointer;
		border: none;
		color: currentColor;
		text-align: left;
		background-color: $white;
		padding: 0.4375em 0.4375em;
		&:focus, &:focus-visible {
			outline: 3px solid $product !important;
			border-color: $product !important;
		}
		&:disabled {
			cursor: default;
		}
		//IE Fixes
		@media all and (-ms-high-contrast: active) {  
			/* Remove purple highlight in HC mode */  
			&:focus::-ms-value {
				background-color: transparent;  /*make the select background transparent in ie */
				color: white;
			}
		}
		@media all and (-ms-high-contrast: none) {  
			/* Remove blue highlight in Normal mode*/  
			&:focus::-ms-value {
				background-color: transparent;  
				color: black;
			}
		}
	}
}
label {
	display: block;
	margin-bottom: 0.4125em;
}
input {
	display: block;
}


// Common inner elements
// ---------------------
button, .button {
	font-size: 1.375em;
	display: block;
	padding: 0.36875em;
	border-radius: $br_normal;
	background-color: $transaction;
	text-align: center;
	color: $white;
	font-family: $heading;
	transition:
		background-color $dt_normal,
		opacity $dt_normal;
	&:hover {
		background-color: darken($transaction, 15%);
	}
	&-product {
		background-color: $product;
		&:hover {
			background-color: darken($product, 7.5%);
		}
	}
	&-page {
		background-color: $product;
		&:hover {
			background-color: darken($product, 7.5%);
		}
	}
	&-small {
		font-family: $body;
		font-size: 0.75em;
		text-transform: uppercase;
		letter-spacing: 2px;
		padding: 0.46875em 0.84375em !important;
	}
	&.is_disabled {
		background-color: $darkgray;
		cursor: default;
	}
}
.button-large {
	padding: 0.4em;
}

.action-link {
	font-size: 0.75em;
	text-transform: uppercase;
	letter-spacing: 2px;
	display: inline-block;
	cursor: pointer;
	&:hover {
		color: darken($transaction, 15%);
	}
}

body.admin-site {
	.button {
		&-product, &-page {
			background-color: $transaction;
			&:hover {
				background-color: darken($transaction, 15%);
			}
		}
		&-manage {
			background-color: $product;
			&:hover {
				background-color: darken($product, 7.5%);
			}
		}
	}
}




// LAYOUT
// --------------------------------------

// Basic view sizing
// -----------------
.page-container {
	min-width: 296px;
	margin: 0 auto;
	width: calc(100% - 1.5em);
	@include w480 {
		width: calc(100% - 3em);
	}
	@include w1000 {
		width: calc(100% - 3em);
	}
}
.content-container {
	&-700 {
		margin: 0 auto;
		width: 100%;
		max-width: 43.875em;
	}
	&-800 {
		margin: 0 auto;
		width: 100%;
		max-width: 50.5em;
	}
}

@include n640 {
	.dn_n640 {
		display: none;
	}
}
@include w640 {
	.dn_w640 {
		display: none;
	}
}


// Basic section spacing
// ---------------------
header {
	height: 3.3125em;
	@include w640 {
		height: 4em;
	}
	@include w1000 {
		height: 4.6875em;
	}
	.fixed-area {
		position: fixed;
		width: 100%;
		background-color: $transaction;
		overflow: hidden;
		z-index: 50;

		// padding: 0.5625em 0 0.6875em;
		height: 3.3125em;
		@include w640 {
			height: 4em;
		}
		@include w1000 {
			height: 4.6875em;
		}
	}
	.page-container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 100%;
	}
	.logo {
		background-size: cover;
		background-size: 100% 100%;
		a {
			display: inline-block;
			font-style: italic;
			transition: opacity $dt_normal;
			color: transparent;
			width: 3.8125em;
			height: 2.09375em;
			@include w640 {
				width: 6.6875em;
				height: 2.625em;
			}
			@include w1000 {
				width: 5.75em;
				height: 3.15625em;
			}
			background-position: 0.125em center;
			background-repeat: no-repeat;
			&:hover {
				opacity: 0.69125;
			}
		}
	}
	.nav-menu-toggle {
		width: 2em;
		height: 2em;
		margin-left: 1em;
		position: relative;
		a, button {
			position: absolute;
			display: block;
			width: 100%;
			height: 100%;
			background-repeat: no-repeat;
			background-position: right center;
			transform: translateY(0);
			font-size: initial;
			&:hover {
				background-color: transparent;
			}
			&.open {
				background-image: url('/assets/images/nav-menu-open.svg');
				background-position: center;
				background-size: 1.5em;
				transform: translateY(0);
				transition:
					transform 0.25s ease-out,
					opacity 0.25s ease-in;
				&:hover {
					opacity: 0.69125;
				}
			}
			&.close {
				background-image: url('/assets/images/nav-menu-close.svg');
				background-position: right 0.1875em top 0.45625em;
				background-size: 1.1875em;
				transform: translateY(-150%);
				opacity: 0;
				transition:
					transform 0.25s ease-out,
					opacity 0.09375s ease-out;
			}
		}
	}
	.nav-menu-cart {
		width: 2em;
		height: 2em;
		margin-left: auto;
		position: relative;
		a {
			position: absolute;
			display: block;
			left: -.125em;
			width: 100%;
			height: 100%;
			background-image: url('/assets/images/nav-menu-cart.svg');
			background-repeat: no-repeat;
			background-position: right center;
			background-size: 1.5em;
			&.active {
				opacity: 1;
				transition:
					transform 0.25s ease-out,
					opacity 0.09375s ease-out;
				&:hover {
					opacity: 0.69125;
				}
			}
			&.inactive {
				cursor: default;
				pointer-events: none;
				opacity: 0.5;
			}

		}
		.item-amount {
			background-color: $product;
			position: absolute;
			display: block;
			top: -.5em;
			right: .75em;
			color: $white;
			font-family: $body;
			font-size: 13px;
			font-weight: bold;
			line-height: 18px;
			border-radius: 100px;
			overflow: hidden;
			text-align: center;
			transform: translateX(100%);
			white-space: nowrap;
			width: 1.375em;
			height: 1.375em;
			z-index: 100;
		}
		> span {
			font-size: 13px;
			line-height: 18px;
		}
	}
}
body.admin-site {
	header {
		.fixed-area {
			background-color: darken($gray, 9.375%);
		}
	}
}
nav {
	position: fixed;
	height: 100vh;
	overflow: scroll;
	width: 100vw;
	top: 0;
	left: 0;
	background-color: $white;
	z-index: 40;
	box-shadow: 0 0 5em 1em rgba($black, 0);

	padding-top: 1.125+3.25em;
	transform: translateY(-100%);
	@include w640 {
		padding-top: 1.125+4em;
		transform: translateY(-100%);
	}
	@include w1000 {
		padding-top: 1.125+4.6875em;
		transform: translateY(-100%);
	}


	transition:
		transform 0.25s ease-out,
		box-shadow 0.25s ease-out;
		// opacity 0.25s ease-out;
	ul {
		&:nth-last-child(n+2) {
			border-bottom: 2px solid darken($gray, 5%);
			padding-bottom: 0.625em;
			margin-bottom: 0.875em;
		}
		li {
			a {
				display: block;
				color: $offblack;
				font-size: 1.125em;
				// line-height: 1;
				padding: 0.6875em 0;
				&:hover {
					color: $transaction;
				}
			}
		}
	}
}
body.show_nav_menu {
	header {
		.nav-menu-toggle {
			a, button {
				&.open {
					transform: translateY(150%);
					opacity: 0;
					pointer-events: none;
					transition:
						transform 0.25s ease-out,
						opacity 0.09375s ease-out;
				}
				&.close {
					transform: translateY(0);
					opacity: 1;
					pointer-events: auto;
					transition:
						transform 0.25s ease-out,
						opacity 0.25s ease-in;
					&:hover {
						opacity: 0.69125;
					}
				}
			}
		}
	}
	nav {
		// opacity: 1;
		transform: translateY(0);
		box-shadow: 0 0 5em 1em rgba($black, 0.5);
		transition:
			transform 0.25s ease-out,
			box-shadow 0.09375s ease-out;
			// opacity 0.09375s ease-out;
	}
}
body.clear_page {
	section {
		opacity: 0.4375;
	}
	footer {
		visibility: hidden;
	}
}

.mobile-cta {
	.fixed-area {
		position: fixed;
		z-index: 30;
		bottom: 0;
		width: 100%;
		padding: 0.75em 0;
		background-color: $white;
		box-shadow: 0 0 0.125em 0.125em rgba($black, 0.140625);

		transform: translateY(100%);
		opacity: 0;
		pointer-events: none;

		transition:
			transform $dt_normal,
			opacity $dt_normal;
	}
	@include n640 {
		// height: 4.84375em;
		.fixed-area {
			transform: translateY(0);
			opacity: 1;
			pointer-events: auto;
		}
	}
	&+ footer .legal {
		padding: 4.75em 0 6.5em;
		@include w640 {
			padding: 4.75em 0 11.75em;
		}
	}
}

aside {
	&+ .mobile-cta {
		@include n1000 {
			.fixed-area {
				transform: translateY(0);
				opacity: 1;
				pointer-events: auto;
			}
		}
	}
}

footer {
	height: 0;
	margin-top: 2em;
	transition:
		height $dt_normal;
	.button-page {
		@include w640 {
			pointer-events: none;
		}
	}
	.legal {
		padding: 4.75em 0 6.5em;
		@include n640 {
			padding-bottom: 1.5em;
			text-align: center;
		}
		a {
			color: $offblack;
			&:hover {
				color: $transaction;
			}
		}
	}
}
// body.show_footer {
// 	&:not(.menu-page) {
// 		footer:not(.no-fixed) {
// 			@include n640 {
// 				height: 4.84375em;
// 				.fixed-area {
// 					transform: translateY(0);
// 					opacity: 1;
// 					pointer-events: auto;
// 				}
// 			}
// 		}
// 	}
// 	&.menu-page {
// 		footer {
// 			@include n1000 {
// 				height: 4.84375em;
// 				.fixed-area {
// 					transform: translateY(0);
// 					opacity: 1;
// 					pointer-events: auto;
// 				}
// 			}
// 		}
// 	}
// }

section {
	padding: 1.5em 0 0.25em;
	@include n480 {
		padding: 2.5em 0 0.25em;
	}
	background-color: $white;
	.button-page {
		&:not(.show-all) {
			@include n640 {
				display: none;
			}
		}
	}
}

main {
	//Added for IE and other browser compatibility
	display: block;
	width: 100%;
	@include w1000 {
		width: calc(100% - 18.5em);
	}
	@include w1440 {
		width: calc(100% - 20.3125em);
	}
}
aside, .aside-detached {
	padding: 2.5em 1.375em;
	background-color: rgba($gray, 0.1875);

	@include w1000 {
		padding: 4.125em 1.375em 5.6875em;
		position: fixed;
		top: 4.6875em;
		right: 0;
		height: 100%;
		overflow: scroll;
		width: 18.5em;
	}
	@include w1440 {
		width: 20.3125em;
	}

	@include n1000 {
		margin: 1.5em auto;
		max-width: 43.875em;

		width: calc(100% - 1.5em);
		@include w480 {
			width: calc(100% - 3em);
		}

		.button-page {
			display: none;
		}
	}

	@include n1000 {
		.order-summary > *:last-child {
			margin-bottom: 0;
		}
	}
}
.aside-detached {
	@include n1000 {
		margin: 4.0625em 0;
		width: 100%;
	}
}

body.admin-site aside {
	background-color: lighten($gray, 4.5%);
}

// article {
// 	ul, ol {
// 		margin-bottom: 1.5em;
// 		line-height: 1.4;
// 	}
// 	ul li {
// 		list-style-type: disc;
// 		list-style-position: outside;
// 		margin-left: 1.125em;
// 		margin-bottom: 0.25em;
// 	}
// }





// MODULES
// --------------------------------------
.hero {
	background-color: $black;
	background-size: cover;
	background-position: center;
	padding: 2.25em 1.2875em;
	text-align: left;
	background-repeat: no-repeat;
	position: relative;
	@include w1000 {
		margin: 0 0 -0.90625em;
		padding: 4.25em 10%;
	}
	.flex-container {
		display: block;
		@include w1000 {
			display: flex;
			align-content: flex-end;
			align-items: center;
			justify-content: center;
		}
	}
	&:before {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background-color: rgba(black, 0.5);
	}
	h1 {
		color: $white;
		flex-basis: 100%;
		font-size: 4.5em;
		line-height: 1;
		margin-bottom: .025em;
		position: relative;
		text-shadow: 0 1px 1px rgba(black, 0.3);
		text-transform: uppercase;
		z-index: 10;
		@include w1000 {
			margin-right: -.75em;
			padding-right: 2.25em;
		}
	}
	ul {
		flex-basis: 100%;
		position: relative;
		z-index: 11;
		@include w1000 {
			border-left: 1px solid rgba(white, 0.5);
			padding-left: 2.25em;
		}
	}
	li{
		background-position: left center;
		background-repeat: no-repeat;
		border-bottom: 1px solid rgba(white, 0.5);
		color: $white;
		font-size: 1.25em;
		font-weight: 800;
		line-height: 1em;
		padding: 1.25em 0 1.25em 2.25em;
		&:last-child {
			border-bottom: none;
		}
	}
	.button {
		max-width: 200px;
		padding: .4em 1.25em;
		position: relative;
		width: auto;
		z-index: 12;
		margin-top: 1em;
		@include w1000 {
			margin-top: 3em;
		}
	}
}
.segment-intro {
	margin-bottom: 1.59375em;
	text-align: center;
	@include w1000 {
		margin: 2.25em 0 3.34375em;
	}
	h1 {
		margin-bottom: 0.5em;
		@include w1000 {
			margin-bottom: 0.296875em;
		}
	}
	p {
		line-height: 1.5;
	}
	&-tighter {
		@include w640 {
			margin-top: 0;
			margin-bottom: 1.5625em;
		}
	}
	&-info {
		color: $offblack;
		margin: 0 auto;
		max-width: 72em;	
		text-align: left;
		h1, h2 {
			font-family: $heading-alt;
		}
		h1 {
			font-size: 1.5em;
		}
		h2 {
			font-size: 1em;
		}
		hr {
			margin: 2.25em 0;
		}
		img {
			border: 4px solid $transaction;
			box-shadow: .75em .75em $gray;
			margin-bottom: 2.25em;
			width: calc(100% - 1.09375em);
			@include w640 {
				box-shadow: 1.25em 1.25em $gray;
				margin-bottom: 0;
				width: calc(100% - 1.09375em);
				}
		}
		.link-back {
			padding-bottom: 2.25em;
			text-align: center;
			 a {
				color: $black;
				text-decoration: underline;
			}
		}
		.row {
			display: inline-block;
			display: flex;
			align-items: center;
			flex-direction: column;
			justify-content:space-between;
			margin: 0 auto;
			@include w640 {
				flex-direction: row;
			}
		}
		.col{
			justify-content: space-between;
			order: 2;
			width: auto;
			&:last-child {
				order: 1;
				padding-bottom: 1em;
			}
			@include w640 {
				flex-direction: column-reverse;
				width: calc(50% - 1.09375em);
			}
		}
	}
}

body.admin-site {
	.segment-intro {
		p {
			display: none;
		}
	}
}

.menu-blocks {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	.menu-block {
		width: 100%;
		@include w640 {
			width: calc((100%/2) - 0.7125em);
		}
		@include w1440 {
			width: calc((100%/3) - 0.9375em);
		}
		@include w1920 {
			width: calc((100%/4) - 1.09375em);
		}
	}
}

.menu-block {
	position: relative;
	margin-bottom: 1.4125em;
	.content {
		padding: 1.1875em 1.2875em 1.45625em;
		border: 2px solid $gray;
		border-top: none;
		border-bottom-right-radius: $br_normal;
		border-bottom-left-radius: $br_normal;
		overflow: hidden;
    background-color: rgba($gray, 0.25);
		h2 {
			margin-bottom: 0.21875em;
		}
		p {
			font-size: 0.84375em;
			margin-bottom: 0;
			line-height: 1.325;
		}
	}
	figure {
		border: 2px solid $gray;
		background-color: $gray;
		border-bottom: none;
		aspect-ratio: 53 / 30;
		background-color: desaturate($transaction, 43%);
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		position: relative;
		border-top-right-radius: $br_normal;
		border-top-left-radius: $br_normal;
		overflow: hidden;
	}
	.sold-out{
		&::before {
			content: "";
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background-color: rgba(black, 0.65);
		}
		.item-amount {
			background-color: $alert;
		}
	}

	// .menu-block-intro
	&-intro {
		border-top: 0.5em solid $product;
		.content, .content:first-child {
			border-top: none;
			border-top-right-radius: 0;
			border-top-left-radius: 0;
		}
	}

	// .menu-block-product
	&-product {
		// overflow: hidden;
		display: flex;
		flex-direction: column;
		menu-item {
			display: flex;
			flex-direction: column;
			flex-grow: 1;
		}
		.content {
			display: flex;
			flex-direction: column;
			flex-grow: 1;
		}
		.item-amount {
			display: flex;
			background-color: $transaction;
			padding-left: 20px;
			top: 0;
			right: 0;
			color: $white;
			padding: 0.875em 0.75em 0.75em 0.875em;
			font-family: $heading;
			border-top-right-radius: $br_normal;
			border-bottom-left-radius: $br_normal;
			overflow: hidden;
			transform: translateX(100%);

			white-space: nowrap;

			opacity: 0;
			transition:
				opacity $dt_normal,
				transform $dt_normal;

			.item-value, .item-unit {
				font-size: 1.375em;
				line-height: 1;
			}
			.item-value {
				display: inline-block;
				position: relative;

				color: transparent;
				.display {
					position: absolute;
					color: transparent;
					transition:
						left $dt_normal,
						color $dt_normal;
					&-current {
						left: 0;
						color: $white;
					}
					&-previous {
						left: -100%;
					}
					&-next {
						left: 100%;
					}
				}
			}
		}
		.product-details {
			margin-bottom: 1.5625em;
			padding-top: 0.625em;
			display: flex;
			justify-content: space-between;
			flex-grow: 1;
			h2 {
				line-height: 1;
				margin-bottom: 0.125em;
			}
			.description {
				p {
					padding-right: 1.625em;
				}
			}
			.pricing {
				text-align: right;
				width: auto;
				margin-left: 0;
				flex-shrink: 0;
			}
		}
		&.option_selected {
			.product-selection {
				.quantity-select {
					width: calc(50% - 0.7125em);
					color: $product;
				}
			}
		}
		&.adding_to_order {
			.product-selection {
				.button {
					transition: none;
					background-color: rgba($product, 0.625);
				}

			}
		}
		&.selection_added {
			.item-amount {
				opacity: 1;
				transform: translateX(0);
			}
		}

	}
	&-blank {
		height: 0;
		margin: 0;
		border: none;
	}
}

body.admin-site {
	.menu-block {
		.content {
			border: 2px solid lighten($gray, 4.5%);
			border-radius: $br_normal;
			background-color: lighten($gray, 4.5%);
		}
		figure {
			display: none;
		}
	}
}

.sample-space {
	padding-top: 100%;
	background-color: desaturate($transaction, 43%);
	margin-top: 1em;
}

.product-selection {
	display: flex;
	justify-content: space-between;
	align-items: center;
	transition: opacity $dt_normal;
	font-family: $heading;
	> div {
		display: flex;
		align-items: center;
	}
	label {
		margin-top: 0.4125em;
		margin-left: 0.5em;
		color: $black;
		flex-grow: 1;
		font-size: 1.375em;
		line-height: 1;
	}
	.quantity-input {
		width: 3.09375em;
		flex-shrink: 0;
	}
	.quantity-select {
		width: 10em;
		flex-shrink: 0;
	}
	.button {
		width: 3.75em;
		flex-shrink: 0;
		font-size: 1.625em;
		padding: 0.109375em;
	}
	.styled-select {
		flex-grow: 1;
		margin: 0 0.36875em;
	}
	&-review {
		justify-content: flex-start;
		> div {
			label {
				@include n640 {
					margin-left: 0.5em;
					margin-top: 0.53125em;
					font-size: 0.875em;
				}
			}
		}
		.quantity-input {
			@include n640 {
				font-size: 1rem;
				padding-left: 0.25rem;
				padding-right: 0.25rem;
				width: 3.25rem;
			}
		}
		.static-input {
			color: $black;
			flex-grow: 1;
			font-size: 1.375em;
			line-height: 1;
		}
		.button {
			// Overriding button rules for product selection
			width: auto;
			font-size: 0.875em;
			@include w640 {
				font-size: 0.75em;
			}

			margin-left: 1.5em;
			margin-right: 1.5em;
			margin-top: 0.1875em;
			transform: translateX(-12.5%);
			pointer-events: none;
			opacity: 0;
			transition:
				background-color $dt_normal,
				transform $dt_normal,
				opacity $dt_normal;

			@include n640 {
				//position: absolute;
				//right: 0;
				//margin-top: 0.84375em;
			}
		}
	}
}


.full-order {
	table {
		margin-bottom: 3.71875em;
		width: 100%;
		max-width: 100%;
		position: relative;
		h3 {
			font-size: 1em;
			@include w640 {
				font-size: 1.36875em;
			}
		}
		tr {
			border-bottom: 2px solid darken($gray, 5%);
			max-width: 100%;
			width: 100%;
			&.amount_changed {
				.button {
					transform: translateX(0);
					opacity: 1;
					pointer-events: auto;
				}
			}
			&.updating_order {
				td:not(:nth-child(2)), .product-selection-review div {
					opacity: 0.4375;
					transition: none;
				}
				.button {
					transform: translateX(0);
					opacity: 0.4375;
					pointer-events: auto;
					transition: none;
				}
			}
			&.amount_changed, &.updating_order {
				@include n640 {
					td:nth-child(n+3) {
						opacity: 0;
						pointer-events: none;
					}
				}
			}
			&.test_state {
				.button {
					transform: translateX(12.5%);
					opacity: 0;
					pointer-events: none;
				}
			}
			td {
				vertical-align: middle;
				padding-top: 1.5em;
				padding-bottom: 1.5em;
				transition: opacity $dt_normal;

				// prevent product name column from shrinking too much
				&:nth-child(1) {
					h2 {
						overflow: hidden;
						text-overflow: ellipsis;
						@include n1000 {
							font-size: 1.25em !important;
						}
						@include n640 {
							font-size: 1em !important;
							max-width: 4.15625em;
						}
						@media screen and (min-width: 750px) {
							white-space: nowrap;
							max-width: 17rem;
						}
					}
				}

				// set product selection column to "grow"
				&:nth-child(2) {
					width: 100%;
					@include n640 {
						font-size: 80%;
					}
				}

				// horizontal padding to all columns except first and last
				&:not(:first-child):not(:last-child) {
					padding-left: 0.625em;
					padding-right: 0.625em;
					@include w640 {
						padding-left: 1.59375em;
						padding-right: 1.59375em;
					}
				}

				&:last-child {
					text-align: right;
				}
			}
		}
	}
}

.order-summary {
	&-standalone {
		@include w640 {
			max-width: 18.9375em;
			margin-left: auto;
		}
	}
	&-split {
		display: flex;
		justify-content: flex-end;
		> div {
			@include w640 {
				width: calc(50% - 0.75em);
			}
		}
	}
	table {
		width: 100%;
		margin-bottom: 2em;
		tr {
			td {
				padding-top: 0.59375em;
				padding-bottom: 0.53125em;
				&:first-child {
					width: 100%;
				}
				&:not(:first-child):not(:last-child) {
					padding-left: 0.5em;
					padding-right: 0.5em;
				}
				&:last-child {
					text-align: right;
					white-space: nowrap;
				}
			}
		}
		tbody {
			tr {
				border-bottom: 2px solid darken($gray, 5%);
			}
		}
		tfoot {
			font-weight: $bold;
		}
	}
	.button {
		margin-bottom: 1.375em;
	}
}

.notice {
	padding: 0.30625em 0.875em;
	text-align: center;
	background-color: lighten($gray, 5%);
	color: darken($black, 15%);
	margin-bottom: 0.5em;
	&-info {
		margin: 20px 0px;
		@include n640 {
			margin: 38px 0px 0px 0px;
		}
		padding: 0.9em 1.5em;
		border: 1px solid $transaction;
		background-color: lighten($transaction, 35%);
	}

	&-no-margin {
		margin: 0px;
	}
	
	&-alert {
		color: $alert;
	}
	&-product {
		color: $product;
	}
	&-dark {
		padding: 3.25em 0.875em 3.125em;
		background-color: $black;
		color: $white;
		a {
			text-decoration: underline;
			color: $white;
			&:hover {
				color: darken($white, 20%);
			}
		}
	}
	strong {
		font-weight: $bold;
		text-transform: uppercase;
		letter-spacing: 2px;
		font-size: 0.875em;
	}
	.split {
		@include w640 {
			display: flex;
			flex-wrap: wrap;
			width: 100%;
			justify-content: space-between;
			span {
				flex-shrink: 0;
				&:first-child {
					padding-right: 0.5em;
				}
				// display: block;
			}
		}
		@include n640 {
			span {
				display: block;
				text-align: left;
			}
		}
	}
	em {
		font-style: normal;
		font-weight: $bold;
	}
	// @include w640 {
		&-fixed {
			height: 2.25em;
			@include n640 {
				height: 5em;
			}
		}
		&-fixed {
			padding: 0;
			background-color: transparent;
			.fixed-area {
				background-color: lighten($gray, 5%);
				position: fixed;
				padding: 0.30625em 0.875em;
				width: 100%;
				z-index: 30;
				top: 3.3125em;
				@include w640 {
					top: 4em;
				}
				@include w1000 {
					top: 4.6875em;
				}
			}
		}
	// }
	&-feature {
		padding-top: 1.7125em;
		padding-bottom: 1em;
		margin-bottom: 1.5em;
		h2, h3 {
			font-family: $body;
			font-size: 1.25em;
			font-weight: $regular;
			margin-top: 0.5em;
		}
		h2 {
			letter-spacing: 2px;
		}
		// strong {
		// 	font-size: 125%;
		// 	color: $product;
		// 	display: block;
		// }
		> div:nth-last-child(n+2) {
			margin-bottom: 2em;
		}
	}
	&-order {
		text-align: left;
		margin-bottom: 2.125em;
	}
	&.dn_w1000 {
		@include w1000 {
			display: none;
		}
	}
}

.date-selection {
	.controls {
		> div {
			margin: 2.5em 0;
			label {
				margin-bottom: 0.875em;
			}
		}
		@include w640 {
			display: flex;
			justify-content: space-between;
			width: 100%;
			> div {
				margin: 0.71875em 0 2.5em;
				width: calc(50% - 0.75em);
				label {
					margin-bottom: 1.75em;
				}
			}
		}
	}
	.button {
		max-width: 21.25rem;
		margin: 5rem auto 0;
	}
	select {
		font-family: $body;
		font-size: 1.125em;
		padding: 0.65625em 0.5625em;
	}
}

.payment-form {
	margin-bottom: 4em;
	h2 {
		// padding-bottom: 0.375em;
		margin-bottom: 0.7125em;
	}
	label {
		margin-bottom: 0.25em;
		font-weight: $bold;
	}
	input, .styled-select, textarea, .InputElement {
		text-align: left;
		margin-bottom: 1.25em;
		border-width: 1px;
		border-color: darken($gray, 12.5%);
	}
	input, select, textarea, .InputElement {
		font-family: $body;
		font-size: 1em;
		padding: 0.8125em 0.625em 0.5625em;
	}
	.styled-select select {
		padding: 0.5625em 0.625em;
		line-height: 1.5;
	}
	textarea {
		padding-top: 0.5em;
		line-height: 1.65;
	}
	.split {
		display: flex;
		justify-content: space-between;
		> * {
			width: calc(50% - 0.75em);
		}
	}
	.form-space {
		margin: 4.0625em 0;
	}

	&.form-submitted {
		.invalid {
			border: solid 1px $alert;
		}
		.styled-select .invalid {
			border: none;
			color: $alert;
		}
	}
}

.overlay-authorizing {
	position: fixed;
	z-index: 100;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba(white, 0.875);
	.spinner {
		> div {
			width: 1.125em;
			height: 1.125em;
			background-color: $product;
			border-radius: 100%;
			display: inline-block;
			animation: sk-bouncedelay 1.4s infinite ease-in-out both;
			&:nth-child(1) {
				animation-delay: -0.32s;
			}
			&:nth-child(2) {
				animation-delay: -0.16s;
			}
		}
	}
}

@-webkit-keyframes sk-bouncedelay {
	0%, 80%, 100% { transform: scale(0) }
	40% { transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
	0%, 80%, 100% {
		transform: scale(0);
	} 40% {
		transform: scale(1.0);
	}
}

.admin-fill {
	border: 2px solid $gray;
	box-shadow: inset 0 0 3em 0 rgba($darkgray, 0.125);
	min-height: calc(100vh - 7.6875em);
	padding: 1.5em;
}

hr {
	border: none;
	background-color: darken($gray, 9.375%);
	height: 2px;
	margin: 5em 0 2em;
}
aside {
	hr {
		margin: 3em 0;
	}
}

.info-blocks {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	flex-wrap: wrap;
	.info-block {
		border: 1px solid $gray;
		border-radius: $br_normal;
		margin-bottom: 1.4125em;
		padding: 1.5em;
		position: relative;
		width: 100%;
		height: 18em;
		@include w640 {
			width: calc(50% - 1.09375em);
		}
		&:last-child {
			align-self: center;
			justify-content: center;
			background-color: rgba($transaction, 0.25);
			border-color: rgba($transaction, 0.5);
			height: 12.5em;
			margin-left: auto;
			margin-right: auto;
			.header {
				border-color: rgba($transaction, 0.5);
			}
		}
		}
	.header {
		border-bottom: 1px solid $gray;
		margin-bottom: 1.1875em;
		padding: 0 0 1.1875em 0;
		overflow: hidden;		
		h2 {
			background-position: left center;
			background-repeat: no-repeat;
			color: $offblack;
			font-family: $heading-alt;
			font-size: 1em;
			height: 1.8em;
			line-height: 1.8em;
			padding-left: 2.25em;
		}
		p {
			font-size: 0.84375em;
			margin-bottom: 0;
			line-height: 1.325;
		}
	}
	figure {
		height: 20.0625em;
		position: relative;
		border-top-right-radius: $br_normal;
		border-top-left-radius: $br_normal;
		overflow: hidden;
	}
}

.gratuity-area {
	display: flex;
	.styled-select.gratuity-select {
		width: 6em;
		border: 1px solid transparent;
		font-size: 10px;
		select {
			font-family: $body;
			font-weight: $bold;
		}
	}
}

// Utilities

.no-outline {
	outline: none;
}

.black-underline {
	text-decoration: underline;
	color: $black;
}

.terms-area {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
	margin-bottom: 2em;
}

:root {
	--form-control-color: $black;
	--form-control-disabled: #959495;
  }

input[type="checkbox"] {
	/* Add if not using autoprefixer */
	-webkit-appearance: none;
	/* Remove most all native input styles */
	appearance: none;
	/* For iOS < 15 */
	background-color: var(--form-background);
	/* Not removed via appearance */
	margin: 0;
  
	font: inherit;
	color: currentColor;
	width: 1.15em;
	height: 1.15em;
	border: 0.15em solid currentColor;
	border-radius: 0.15em;
	transform: translateY(-0.075em);
  
	display: grid;
	place-content: center;
  }
  
  input[type="checkbox"]::before {
	content: "";
	width: 0.65em;
	height: 0.65em;
	clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
	transform: scale(0);
	transform-origin: bottom left;
	transition: 120ms transform ease-in-out;
	box-shadow: inset 1em 1em var(--form-control-color);
	/* Windows High Contrast Mode */
	background-color: CanvasText;
  }
  
  input[type="checkbox"]:checked::before {
	transform: scale(1);
  }
  
  input[type="checkbox"]:focus {
	outline: max(2px, 0.15em) solid currentColor;
	outline-offset: max(2px, 0.15em);
  }
  
  input[type="checkbox"]:disabled {
	--form-control-color: var(--form-control-disabled);
  
	color: var(--form-control-disabled);
	cursor: not-allowed;
  }
